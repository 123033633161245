<template>
  <a-modal v-model="visible" title="移动" width="680px" class="createMilestoneModal" @ok="save">
    <edp-tree :data="topList" @change="clickTree" row-key="oid" :flag="true" @fromSun="getFolderListChildren">
      <template v-slot="{row}">
        <div class="tree-row">
          <img :src="$toUrl('folder_open.png')" class="dir" alt="">
          <div class="name">{{row.folder_name}}</div>
        </div>
      </template>
    </edp-tree>
  </a-modal>
</template>

<script>
import EdpTree from "@/components/edpTree.vue";
import {fetch} from "@/utils/request";
export default {
  name: "attFolderModal",
  components: { EdpTree },
  props: {
    prjOid: {
      type: String
    }
  },
  data() {
    return{
      visible: false,
      topList: [],
      oid: '',
      cur_folder_oid: ''
    }
  },
  created() {
  },
  methods: {
    open(oid) {
      console.log(oid);
      this.oid = oid
      this.getFolderListTop()
      this.visible = true
    },
    clickTree(row) {
      console.log(row);
      this.cur_folder_oid = row.oid
    },
    getFolderListTop() {
      fetch('post','/prj/folder/retrieve/toplevel',{
        prjoid: this.prjOid,
      }).then((res)=>{
        if (res.status === 0) {
          this.topList = res.data
          console.log('顶级文件夹', this.topList);
        }else {
          this.$message.error(res.message)
        }
      })
    },
    getFolderListChildren(row) {
      console.log(row);
      fetch('post','/prj/folder/retrieve/children',{
        prjoid: this.prjOid,
        oid: row.oid
      }).then((res)=>{
        if (res.status === 0) {
          console.log('子级文件夹', res.data);
          if (res.data.length > 0) this.$set(row, 'children', res.data)
        }else {
          this.$message.error(res.message)
        }
      })
    },
    save() {
      if (this.cur_folder_oid) {
        fetch('post','/attachment/change/folder',{
          oid: this.oid,
          prj_folderoid: this.cur_folder_oid
        }).then((res)=>{
          if (res.status === 0) {
            this.$emit('reload')
            this.visible = false
            this.$message.success('移动成功！')
          }else {
            this.$message.error(res.message)
          }
        })
      } else {
        this.$message.info('请选择要移动的文件夹！')
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .edp-tree{
  .edp-tree-list {
    .edp-tree-item {
      &.active {
        >.tree-item-row >.tree-row {
          .menu-icon {
            display: block;
          }
        }
      }
      >.tree-item-row {
        .tree-row{
          .name{
            width: 100%;
          }
        }
        .menu-icon {
          display: none;
        }
        &:hover {
          .menu-icon {
            display: block;
          }
        }
      }
    }
  }
}
.tree-row {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  .dir {
    margin-right: 5px;
    height: 22px;
  }
  .menu-icon .iconfont{
    font-size: 16px;
    font-weight: 500;
  }
}

</style>