import { render, staticRenderFns } from "./attFolderModal.vue?vue&type=template&id=5f1e6e70&scoped=true&"
import script from "./attFolderModal.vue?vue&type=script&lang=js&"
export * from "./attFolderModal.vue?vue&type=script&lang=js&"
import style0 from "./attFolderModal.vue?vue&type=style&index=0&id=5f1e6e70&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f1e6e70",
  null
  
)

export default component.exports