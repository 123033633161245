<template>
  <a-modal v-model="visible" :title="title" width="680px" class="uploadModal">
    <a-form-model layout="vertical" ref="form" :model="form" :rules="rules" class="edp-form">
      <a-form-model-item label="文件名称" prop="folder_name">
        <a-input v-model="form.folder_name"></a-input>
      </a-form-model-item>
    </a-form-model>
    <template #footer>
      <div class="modal-footer">
        <a-space>
          <a-button @click="visible = false">
            取消
          </a-button>
          <a-button type="primary" @click="submit">
            确定
          </a-button>
        </a-space>
      </div>
    </template>
  </a-modal>
</template>

<script>

import {fetch} from "@/utils/request";

export default {
  name: "createFolderModal",
  data() {
    return {
      form: {
        folder_name: ''
      },
      title: '',
      rules: {
        folder_name:[{required: true, message: "请填写文件夹名称", trigger: 'change'}]
      },
      visible: false,
      backup: {}
    }
  },
  created() {
    this.backup = JSON.parse(JSON.stringify(this.form))
  },
  mounted() {
  },
  methods: {
    open(row) {
      console.log(row);
      let { edit, prjoid, route_level } = row
      this.title = edit ? '编辑文件夹' : '新增文件夹'
      this.form = {
        ...this.backup,
        folder_name: edit ? row.folder_name : '',
        oid: edit ? row.oid : '',
        route_map: !edit ? row.oid : '',
        prjoid,
        route_level: !edit ? (route_level - 0) + 1 : ''
      }
      console.log(this.form);
      this.visible = true
    },
    submit(){
      this.$refs.form.validate(valid => {
        if (valid) {
          let url = this.title === '新增文件夹' ? '/prj/folder/create' : '/prj/folder/update'
            fetch('post', url, this.form).then((res) => {
              if (res.status === 0) {
                console.log(res.data);
                this.visible = false
                if (this.form.route_level === 0) this.$emit('reload')
                if (this.title === '编辑文件夹') {
                  this.$emit('rename', res.data.folder_name)
                }else {
                  this.$emit('showChild', res.data)
                }
                this.$message.success(`${this.title === '新增文件夹' ? '新增成功！' : '编辑成功！'}`)
              } else {
                this.visible = false
                this.$message.error(res.message)
              }
            })
          }
      });
    }
  }
}
</script>

<style scoped>

</style>