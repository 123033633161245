<template>
  <div class="edp-tree">
    <edp-tree-children :data="data" :deep="0" :children-key="childrenKey" :flag="flag" v-on="$listeners" :row-key="rowKey">
      <template v-slot="{row, open, fun}">
        <slot :row="row" :open="open" :fun="fun"></slot>
      </template>
    </edp-tree-children>
  </div>
</template>

<script>
import EdpTreeChildren from "@/components/edpTreeChildren.vue";

export default {
  name: "edpTree",
  emits: ["change"],
  components: {EdpTreeChildren},
  props: {
    disable: {
      type: Array,
      default: () => []
    },
    checkbox: {
      type: Boolean,
      default: () => false
    },
    rowKey: {
      type: String,
      default: () => "key"
    },
    childrenKey: {
      type: String,
      default: () => 'children'
    },
    openAll: {
      type: Boolean,
      default: () => false
    },
    data: {
      type: Array,
      default: () => []
    },
    flag: { // 显示所有向下箭头用接口查询子级
      type: Boolean,
      default: () => false
    }
  },
  provide() {
    return {
      app: this
    }
  },
  data() {
    return {
      checkedKeys: [],
      openKeys: [],
      selectKey: -1
    }
  },
  methods: {
    handleClickChecked(id) {
      let index = this.checkedKeys.findIndex(item => item === id)
      if (index !== -1) {
        this.checkedKeys.splice(index, 1)
      } else {
        this.checkedKeys.push(id)
      }
      this.$emit("change", this.checkedKeys)
    },
    setChecked(checked) {
      this.checkedKeys = checked
    },
    setSelectKey(item) {
      if (this.checkbox) {
        console.error("当传入checkbox=true时不应再触发此函数")
        return
      }
      this.selectKey = item[this.rowKey]
      this.$emit("change", item)
    }
  }
}
</script>

<style lang="less">
.edp-tree {
  .edp-tree-list {
    >.edp-tree-item {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      &.open {
        > .tree-item-children {
          display: block;
        }
        > .tree-item-row {
          > .tree-item-row-icon {
            transform: rotate(90deg);
          }
        }
      }
      &.active {
        >.tree-item-row {
          background-color: #EAF4FC;
          color: #0088ff;
        }
      }
      &:not(.active), &:not(.disabled) {
        >.tree-item-row:hover {
          background-color: #efefef;
        }
      }
      > .tree-item-row {
        margin-bottom: 3px;
        padding-left: calc(10px + var(--deep) * 25px);
        cursor: pointer;
        display:flex;
        align-items: center;
        min-height: 35px;
        .tree-row{
          .name{
            width: 130px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
        .tree-item-row-icon {
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 3px;
          border-radius: 5px;
          flex-shrink: 0;
          .anticon {
            font-size: 14px;
          }
          &:hover {
            color: #0088ff;
            background-color: rgba(0,0,0,.05);
          }
        }
      }
      > .tree-item-children {
        display: none;
      }
    }
  }
}
</style>