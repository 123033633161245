<template>
  <div class="edp-tree-list">
    <div
      class="edp-tree-item"
      v-for="(item) in data"
      :key="item[options.rowKey]"
      :class="{
        open: openKeys.includes(item[options.rowKey]),
        active: selectedKey === item[options.rowKey],
        disabled: disable.includes(item[options.rowKey])
      }"
    >
      <div class="tree-item-row" @click="handleClick(item, item[options.rowKey], $event)" :style="`--deep: ${deep}`">
        <div
          class="tree-item-row-icon"
          v-if="item[options.childrenKey]?.length > 0 || flag"
          @click.stop="handleClickIcon(item, item[options.rowKey])"
        >
       
          <i class="iconfont icon-xiangxia" style="transform: rotate(-90deg);font-size: 12px;" v-if="item[options.childrenKey]?.length > 0 || flag"/>
        </div>
        <a-checkbox
          :disabled="disable.includes(item[options.rowKey])"
          :checked="checkedKeys.includes(item[options.rowKey])"
          v-if="options.checkbox === true && (!item[options.childrenKey] || item[options.childrenKey].length === 0)"
          style="margin-right: 5px; pointer-events: none;"
        />
        <slot :row="item" :open="openKeys.includes(item[options.rowKey])" :fun="addOpen"></slot>
      </div>
      <div class="tree-item-children" v-if="item[options.childrenKey]?.length > 0">
        <edp-tree-children :data="item[options.childrenKey]" :flag="flag" v-on="$listeners" :deep="deep + 1">
          <template v-slot="{row, open, fun}">
            <slot :row="row" :open="open" :fun="fun"></slot>
          </template>
        </edp-tree-children>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "edpTreeChildren",
  inject: ["app"],
  props: {
    data: {
      type: Array,
      default: () => []
    },
    deep: {
      type: Number,
    },
    flag: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      openKeys: [],
    }
  },
  mounted() {
    if (this.app.openAll) {
      this.openKeys.push(
        ...this.data.map(item => {
          return item[this.options.rowKey]
        })
      )
    }
  },
  computed: {
    checkedKeys() {
      return this.app.checkedKeys
    },
    disable() {
      return this.app.disable
    },
    selectedKey() {
      return this.app.selectKey
    },
    options() {
      return {
        rowKey: this.app.rowKey,
        childrenKey: this.app.childrenKey,
        checkbox: this.app.checkbox
      }
    }
  },
  methods: {
    handleClick(item, key) {
      if (!this.disable.includes(item[this.options.rowKey])) {
        if (this.options.checkbox) {
          if (!(item[this.options.childrenKey]?.length > 0)) {
            this.app.handleClickChecked(item.id)
          } else {
            this.handleClickIcon(item, key)
          }
        } else {
          this.app.setSelectKey(item)
        }
      }
    },
    handleClickIcon(item, key) {
      if (item[this.options.childrenKey]?.length > 0 || this.flag) {
        this.toggleOpen(item, key)
      }
    },
    toggleOpen(item, key) {
      let index = this.openKeys.findIndex(item => item === key)
      if (index !== -1) {
        this.openKeys.splice(index, 1)
      } else {
        this.openKeys.push(key)
        if (this.flag) this.$emit('fromSun', item) // 把当前数据给祖级调接口查询
      }
    },
    addOpen(key) {
      this.openKeys = [key]
    }
  }
}
</script>
<style lang="less">
/deep/ .tree-item-row-icon .icon-xiangxia {
  height: 12px;

}
</style>